import rbacConstants from "../../../../utils/constants/rbacConstants";

const rolePermission = JSON.parse(localStorage.getItem("rolePermission"));

export var modules = [
  {
    id: 1, // index = 0
    name: "Positions",
    type: "crud",
  },
  {
    id: 3, // index = 1
    name: "Test",
    type: "crud",
  },
  {
    id: 4, // index = 2
    name: "Candidates",
    type: "crud",
  },
  {
    id: 5, // index = 3
    name: "SkillAreas",
    type: "crud",
  },
  {
    id: 6, // index = 4
    name: "Questions",
    type: "crud",
  },
  {
    id: 7, // index = 5
    name: "ResumeParser",
    type: "crud",
  },
  {
    id: 9, // index = 6
    name: "Insights",
    type: "crud",
  },
  {
    id: 10, // index = 7
    name: "ADCCreate",
    type: "crud",
  },
  {
    id: 11, // index = 8
    name: "ADCConfigure",
    type: "crud",
  },
  {
    id: 12, // index = 9
    name: "ADCAssign",
    type: "crud",
  },
  {
    id: 13, // index = 10
    name: "ADCEvaluate",
    type: "crud",
  },
  {
    id: 14, // index = 11
    name: "ADCReports",
    type: "crud",
  },
  {
    id: 15, // index = 12
    name: "FBConfigure",
    type: "crud",
  },
  {
    id: 16, // index = 13
    name: "FBAssign",
    type: "crud",
  },
  {
    id: 17, // index = 14
    name: "FBReports",
    type: "crud",
  },
  {
    id: 18, // index = 15
    name: "BEDashboard",
    type: "crud",
  },
  {
    id: 19, // index = 16
    name: "OfflineEvaluation",
    type: "crud",
  },
  {
    id: 21, // index = 17
    name: "ESConfigure",
    type: "crud",
  },
  {
    id: 22, // index = 18
    name: "ESAssign",
    type: "crud",
  },
  {
    id: 23, // index = 19
    name: "ESReports",
    type: "crud",
  },
  {
    id: 24, // index = 20
    name: "DownloadAnswerSheets",
    type: "toggle",
  },
  {
    id: 25, // index = 21
    name: "DownloadQuestions",
    type: "toggle",
  },
  {
    id: 26, // index = 22
    name: "EnabledTestTypes",
    type: "list",
  },
  {
    id: 27, // index = 23
    name: "EnabledQuestionTypes",
    type: "list",
  },
  {
    id: 28, // index = 24
    name: "EnabledRoles",
    type: "list",
  },
  {
    id: 29, // index = 25
    name: "AddCredits",
    type: "toggle",
  },
  {
    id: 30, // index = 26
    name: "BECreate",
    type: "crud",
  },
  {
    id: 31, // index = 27
    name: "PFMyProfile",
    type: "toggle",
  },
  {
    id: 32, // index = 28
    name: "PFMyCompany",
    type: "toggle",
  },
  {
    id: 33, // index = 29
    name: "PFTeamManagement",
    type: "toggle",
  },
  {
    id: 34, // index = 30
    name: "PFPlan&Billing",
    type: "toggle",
  },
  {
    id: 35, // index = 31
    name: "PFIntegration",
    type: "toggle",
  },
  {
    id: 36, // index = 32
    name: "PFRegistration",
    type: "toggle",
  },
  {
    id: 37, // index = 33
    name: "PFLocation",
    type: "toggle",
  },
  {
    id: 38, // index = 34
    name: "BOTBot",
    type: "crud",
  },
  {
    id: 39, // index = 35
    name: "TLTranslation",
    type: "crud",
  },
  {
    id: 40, // index = 36
    name: "FAQFAQ",
    type: "crud",
  },
  {
    id: 41, // index = 37
    name: "CFCustomForms",
    type: "crud",
  },
  {
    id: 42, // index = 38
    name: "DownloadBulkReports",
    type: "toggle",
  },
  {
    id: 43, // index = 39
    name: "WFConfigure",
    type: "crud",
  },
  {
    id: 44, // index = 40
    name: "WFAssign",
    type: "crud",
  },
];

const areAllSubModulesHidden = (arr) => {
  if (!Array.isArray(arr)) {
    return false;
  } else if (arr.length == 0) {
    return false;
  } else {
    return arr.every((item) => (item?.type == rbacConstants?.controlTypes?.[0]?.type ? item?.hidden == true : item?.read != true));
  }
};

export var sidebarModules = (permission_code) => {
  let permissionsArray = permission_code || rolePermission;
  return [
    {
      name: "S-Admin Dashboard",
      label: "Dashboard",
      icon: "fas fa-pie-chart",
      // url: "/s-admin/dashboard",
      hidden: false,
      subMenu: [
        {
          name: "SA - Dashboard",
          label: "Dashboard",
          url: "/s-admin/dashboard",
          hidden: false,
        },
        {
          name: "ORG - My Organizations",
          label: "My Organizations",
          url: "/organisation",
          hidden: false,
        },
        // {
        //   name: "ORG - Billing & Collection",
        //   label: "Billing & Collection",
        //   url: "/org-billing",
        //   hidden: false,
        // },
        {
          name: "ORG - RBAC",
          label: "RBAC",
          url: "/rbac",
          hidden: false,
        },
      ],
    },
    {
      name: "Organization",
      label: "Organization",
      icon: "fa fa-university",
      hidden: true, //false,
      subMenu: [
        {
          name: "ORG - My Organizations",
          label: "My Organizations",
          url: "/organisation",
          hidden: false,
        },
        {
          name: "ORG - Billing & Collection",
          label: "Billing & Collection",
          url: "/org-billing",
          hidden: false,
        },
        {
          name: "ORG - RBAC",
          label: "RBAC",
          url: "/rbac",
          hidden: false,
        },
      ],
    },
    {
      name: "Online Tests",
      label: "Online Tests",
      icon: "fas fa-edit",
      hidden: areAllSubModulesHidden(
        permissionsArray?.filter(
          (item) =>
            (item?.belongs_to == rbacConstants?.sections?.[0]?.code && item?.type == rbacConstants?.controlTypes?.[0]?.type) ||
            item?.module_id == modules?.[20]?.id
        )
      ),
      subMenu: [
        {
          name: "OT - Positions",
          label: "My Positions",
          url: "/group",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[0]?.id)?.hidden,
          id: "My-Positions",
        },
        {
          name: "OT - Candidates",
          label: "My Candidates",
          url: "/candidates",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[2]?.id)?.hidden,
          id: "My-Candidates",
        },
        {
          name: "OT - Skill Areas",
          label: "My Skill Areas",
          url: "/skill-area",
          hidden:
            permissionsArray?.find((item) => item?.module_id == modules?.[3]?.id)?.hidden &&
            permissionsArray?.find((item) => item?.module_id == modules?.[4]?.id)?.hidden,
          id: "My-Skill-Areas",
        },
        {
          name: "OT - Tests",
          label: "My Tests",
          url: "/test",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[1]?.id)?.hidden,
          id: "My-Tests",
        },
        {
          name: "ah-detail-rpt",
          label: "Personalized Report",
          url: "/ahdetailrpt",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[20]?.id)?.read != true,
          id: "Detail-Report",
        },
        // {
        //   name: "ah-per-rpt",
        //   label: "Personalized Report",
        //   url: "/ahpersonalizedrpt",
        //   hidden: permissionsArray?.find((item) => item?.module_id == modules?.[20]?.id)?.read != true,
        //   id: "Personalized-Report",
        // },
        {
          name: "ah-parser",
          label: "Resume Parser",
          url: "/resume-parser",
          hidden: !window.location.href.includes("https://admin.myassesshub.com"),
          id: "Resume-Parser",
        },
      ],
    },
    //manual evaluation
    {
      name: "Manual Evaluation",
      label: "Manual Evaluation",
      icon: "fa fa-user-circle",
      hidden: areAllSubModulesHidden(
        permissionsArray?.filter(
          (item) => item?.belongs_to == rbacConstants?.sections?.[1]?.code && item?.type == rbacConstants?.controlTypes?.[0]?.type
        )
      ),
      subMenu: [
        {
          name: "My Test",
          label: "My Test",
          url: "/me/test",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[12]?.id)?.hidden,
          id: "Test",
        },
        {
          name: "My C",
          label: "My Candidates",
          url: "/me/candidates",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[13]?.id)?.hidden,
          id: "Candidates",
        },
      ],
    },
    {
      name: "Feedback Survey",
      label: "Feedback Survey",
      icon: "fas fa-comments",
      hidden: areAllSubModulesHidden(
        permissionsArray?.filter(
          (item) => item?.belongs_to == rbacConstants?.sections?.[1]?.code && item?.type == rbacConstants?.controlTypes?.[0]?.type
        )
      ),
      subMenu: [
        {
          name: "FB - Configure",
          label: "Configure",
          url: "/fb/configure",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[12]?.id)?.hidden,
          id: "Configure",
        },
        {
          name: "FB - Assign",
          label: "Assign",
          url: "/fb/assign",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[13]?.id)?.hidden,
          id: "Assign",
        },
        {
          name: "FB - Reports",
          label: "Reports",
          url: "/fb/report",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[14]?.id)?.hidden,
          id: "Reports",
        },
      ],
    },
    {
      name: "Engagement Survey",
      label: "Engagement Survey",
      icon: "fas fa-address-card ",
      hidden: areAllSubModulesHidden(
        permissionsArray?.filter(
          (item) => item?.belongs_to == rbacConstants?.sections?.[2]?.code && item?.type == rbacConstants?.controlTypes?.[0]?.type
        )
      ),
      subMenu: [
        // {
        //     name: "ES - Create",
        //     label: "Create",
        //     url: "/es/create",
        //     hidden: true,
        // },
        {
          name: "ES - Configure",
          label: "Configure",
          url: "/es/configure",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[17]?.id)?.hidden,
          id: "ES_Config",
        },
        {
          name: "ES - Assign",
          label: "Assign",
          url: "/es/assign",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[18]?.id)?.hidden,
          id: "ES_Assign",
        },
        {
          name: "ES - Reports",
          label: "Reports",
          url: "/es/report",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[19]?.id)?.hidden,
          id: "ES_Reports",
        },
      ],
    },
    {
      name: "Assessment Centre",
      label: "Assessment Centre",
      icon: "fas fa-id-badge",
      hidden: areAllSubModulesHidden(
        permissionsArray?.filter(
          (item) => item?.belongs_to == rbacConstants?.sections?.[3]?.code && item?.type == rbacConstants?.controlTypes?.[0]?.type
        )
      ),
      subMenu: [
        // {
        //     name: "ADC - Create",
        //     label: "Create",
        //     url: "/adc/create",
        //     hidden: permissionsArray?.find(item => item?.module_id == modules?.[7]?.id)?.hidden,
        // },
        {
          name: "ADC - Configure",
          label: "Configure",
          url: "/adc/configure",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[8]?.id)?.hidden,
          id: "adc_configure",
        },
        {
          name: "ADC - Assign",
          label: "Assign",
          url: "/adc/assign",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[9]?.id)?.hidden,
          id: "adc_assign",
        },
        {
          name: "ADC - Evaluate",
          label: "Evaluate",
          url: "/adc/evaluate",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[10]?.id)?.hidden,
          id: "adc_evaluate",
        },
        {
          name: "ADC - Reports",
          label: "Reports",
          url: "/adc/report",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[11]?.id)?.hidden,
          id: "adc_report",
        },
      ],
    },
    {
      name: "Batch Evaluation",
      label: "Batch Evaluation",
      icon: "fa fa-users",
      hidden: areAllSubModulesHidden(
        permissionsArray?.filter(
          (item) => item?.belongs_to == rbacConstants?.sections?.[4]?.code && item?.type == rbacConstants?.controlTypes?.[0]?.type
        )
      ),
      subMenu: [
        {
          name: "BE - Project Insights",
          label: "Project Insights",
          url: "/be/project-insights",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[15]?.id)?.hidden,
        },
        {
          name: "BE - Create",
          label: "Create",
          url: "/be/create-batch",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[26]?.id)?.hidden,
          id: "BE-Create",
        },
      ],
    },
    {
      name: "Workflow",
      label: "Workflow",
      icon: "fa fa-wind",
      hidden: areAllSubModulesHidden(
        permissionsArray?.filter(
          (item) => item?.belongs_to == rbacConstants?.sections?.[11]?.code && item?.type == rbacConstants?.controlTypes?.[0]?.type
        )
      ),
      subMenu: [
        {
          name: "WF - Configure",
          label: "Configure",
          url: "/workflow",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[39]?.id)?.hidden,
          id: "WF-Configure",
        },
        {
          name: "WF - Assign",
          label: "Assign",
          url: "/workflow-assignment",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[40]?.id)?.hidden,
          id: "WF-Assign",
        },
      ],
    },
    {
      name: "Bot",
      label: "Bot",
      icon: "fa fa-robot",
      url: "/bot-api",
      hidden: permissionsArray?.find((item) => item?.module_id == modules?.[34]?.id)?.hidden,
    },
    {
      name: "Translation",
      label: "Translation",
      icon: "fas fa-language",
      hidden: permissionsArray?.find((item) => item?.module_id == modules?.[35]?.id)?.hidden,
      url: "/translatedquestions",
    },
    {
      name: "KnowledgeBase-FAQ",
      label: "InstaQuiz..",
      icon: "fas fa-comment-dots",
      url: "/faq",
      hidden: permissionsArray?.find((item) => item?.module_id == modules?.[36]?.id)?.hidden,
    },
    {
      name: "CF - Custom Forms",
      label: "Custom Forms",
      icon: "fas fa-file-invoice",
      url: "/created-forms",
      hidden: permissionsArray?.find((item) => item?.module_id == modules?.[37]?.id)?.hidden,
    },
    {
      name: "genai_interview",
      label: "GenAI Interview",
      icon: "fa fa-video",
      // TODO: Add RBAC
      // //   hidden: areAllSubModulesHidden(
      //     permissionsArray?.filter(
      //       (item) =>
      //         (item?.belongs_to == rbacConstants?.sections?.[0]?.code && item?.type == rbacConstants?.controlTypes?.[0]?.type) ||
      //         item?.module_id == modules?.[20]?.id
      //     )
      //   ),
      hidden: areAllSubModulesHidden(
        permissionsArray?.filter(
          (item) => item?.belongs_to == rbacConstants?.sections?.[2]?.code && item?.type == rbacConstants?.controlTypes?.[0]?.type
        )
      ),
      subMenu: [
        {
          name: "positions",
          label: "Positions",
          url: "/gen-ai/positions",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[17]?.id)?.hidden,
          id: "video_interview_positions",
        },
        {
          name: "candidates",
          label: "Candidates",
          url: "/gen-ai/candidates",
          hidden: permissionsArray?.find((item) => item?.module_id == modules?.[17]?.id)?.hidden,
          id: "video_interview_candidates",
        },
      ],
    },
  ];
};
